export const eventTypes = {
  CANVAS_DRAW: 'CANVAS_DRAW',
  CLEAR_CANVAS: 'CLEAR_CANVAS',
  CREATE_GAME: 'CREATE_GAME',
  JOIN_GAME: 'JOIN_GAME',
  JOINED_GAME: 'JOINED_GAME',
  PLAYER_JOINED: 'PLAYER_JOINED',
  CHAT: 'CHAT',
  NEW_MESSAGE: 'NEW_MESSAGE',
  GET_CLIENT_INFO: 'GET_CLIENT_INFO',
  UPDATE_GAME_INFO: 'UPDATE_GAME_INFO',
  GET_GAME_INFO: 'GET_GAME_INFO',
  UNNAMED_CLIENT_GAME_JOIN: 'UNNAMED_CLIENT_GAME_JOIN',
  LEAVING_GAME: 'LEAVING_GAME',
  NO_GAME_FOUND: 'NO_GAME_FOUND',
  START_GAME: 'START_GAME',
  NEXT_DRAWER: 'NEXT_DRAWER',
  SHOW_NEW_LETTER: 'SHOW_NEW_LETTER',
  RESTART_GAME: 'RESTART_GAME',
  GAME_OVER: 'GAME_OVER',
  NEW_ROUND: 'NEW_ROUND',
  ASSIGNED_NEW_DRAWER: 'ASSIGNED_NEW_DRAWER',
  WORD_SELECTION: 'WORD_SELECTION'
}